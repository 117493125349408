import {
    Button,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './EnquiryButton.style';

interface EnquiryButtonProps {
    buttonText: string;
    onClick?: () => void;
    disabled: boolean;
    loading?: boolean;
    buttonKey?: string;
    type?: 'button' | 'submit';
}

const EnquiryButton = ({
    buttonText,
    onClick,
    disabled,
    loading,
    buttonKey = '',
    type = 'button',
}: EnquiryButtonProps) => {
    const { mobile } = useAppDisplayModeContext();
    const { css } = useStyles();

    return (
        <div className={css(S.SubmitButton)}>
            <Button
                size={16}
                key={buttonKey}
                type={type}
                color={'dark'}
                {...(mobile && { fluid: true })}
                variables={{
                    iconPadding: 0,
                    buttonPadding: pxToRem(24),
                    buttonMinWidth: pxToRem(245),
                    buttonSize16LetterSpacing: '-0.02em',
                    darkButtonBackgroundColorNormal: '#222222',
                }}
                onClick={() => onClick && onClick()}
                disabled={disabled}
                loading={loading}
            >
                {buttonText.toUpperCase()}
            </Button>
        </div>
    );
};

export default EnquiryButton;
