import {
    Caption,
    pxToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './RecognisedUserWelcome.style';

interface RecognisedUserWelcomeProps {
    welcomeHeader: string;
    welcomeMessage: string;
}

const RecognisedUserWelcome = ({
    welcomeHeader,
    welcomeMessage,
}: RecognisedUserWelcomeProps) => {
    const { mobile } = useAppDisplayModeContext();
    const { css } = useStyles();

    return (
        <>
            <Caption
                variant={mobile ? '02' : '01'}
                design={{
                    marginBottom: pxToRem(mobile ? 12 : 16),
                }}
            >
                {welcomeHeader}
            </Caption>
            <Caption
                variant="04"
                design={{ marginBottom: pxToRem(20) }}
                className={css(S.RecognisedMessageStyles)}
            >
                {welcomeMessage}
            </Caption>
        </>
    );
};

export default RecognisedUserWelcome;
