import { useMatches } from '@remix-run/react';
import type { Directory } from '../type';

const PATH_TO_DIRECTORY_TYPE_MAP: Record<string, Directory> = {
    professional: 'professional',
    product: 'product',
    project: 'project',
    article: 'article',
};

export const useDirectoryURLType = (): Directory | undefined => {
    const matchingRoutes = useMatches();
    if (matchingRoutes.length == 0) {
        return undefined;
    }

    // non-empty segments of the path
    const segments = matchingRoutes[matchingRoutes.length - 1]!.pathname.split(
        '/'
    ).filter((e) => e);

    if (!segments.length) {
        return undefined;
    }

    return PATH_TO_DIRECTORY_TYPE_MAP[segments[0]!];
};
