import { useEffect } from 'react';
import { useUser, isUserLoggedIn } from '~/modules/user';

/**
 * Get the isLoggedIn object for enquiries
 * Handle user being logged and in an enquiry flow state 'login'
 * TICKET: https://archipro.atlassian.net/browse/GROW-3782
 *
 * @param flow
 *      - Is state of the enquiry we want to be checking
 * @param handleEnquiry
 *      - The function we use if user is logged in and flow === 'login'
 * @returns
 *      - isLoggedIn: Shows whether the user is currently logged in
 */
export const useEquiryGetAndHandleIsLoggedIn = (
    flow?: string,
    handleEnquiry?: () => void
) => {
    const user = useUser();
    const isLoggedIn = isUserLoggedIn(user);

    useEffect(() => {
        if (
            (flow === 'login' || flow === 'sign-up') &&
            isLoggedIn &&
            handleEnquiry
        ) {
            handleEnquiry();
        }
    }, [isLoggedIn, flow, handleEnquiry]);

    return isLoggedIn;
};
