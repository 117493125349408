import type { StyleRule } from '@archipro-design/aria';
import {
    dropdownItemSlotClassNames,
    textInputClassName,
} from '@archipro-design/aria';
import { dropdownClassName } from '@archipro-design/aria';
import {
    captionClassName,
    dividerClassName,
    inputSlotClassNames,
    boxClassName,
    inputClassName,
    checkboxClassName,
    checkboxSlotClassNames,
    indicatorCheckboxClassName,
    pxArrayToRem,
    getScrollbarStyle,
    textInputSlotClassNames,
    pxToRem,
    dropdownSlotClassNames,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const SuburbDropdown: StyleRule<{
    visibleRowsSuburbDropdown: number;
}> = ({ visibleRowsSuburbDropdown, theme }) => {
    return {
        [`& .${textInputSlotClassNames.primaryContent}`]: {
            [`& .${inputClassName}`]: {
                [`& .${boxClassName}`]: {
                    minHeight: 'unset',
                },
            },
            [`& .${dropdownSlotClassNames.selectedItems}`]: {
                overflow: 'hidden',
            },
            [`& .${dropdownSlotClassNames.searchInput}`]: {
                padding: 0,
            },
            [`& .${dropdownSlotClassNames.itemsList}`]: {
                cursor: 'pointer',
                padding: 0,
                maxHeight: pxToRem(42 * visibleRowsSuburbDropdown),
                ...getScrollbarStyle(theme, '0'),
            },
            [`& .${dropdownSlotClassNames.item}`]: {
                border: 'none',
            },
        },
    };
};

export const MessageInput: StyleRule = () => {
    return {
        [`&.${textInputClassName}`]: {
            minHeight: pxToRem(150),
            display: 'grid',
        },
        [`& .${inputClassName}`]: {
            [`& .${boxClassName}`]: {
                paddingBottom: pxToRem(3),
                [`& textarea.${inputSlotClassNames.input}`]: {
                    resize: 'none',
                    height: pxToRem(200) + '!important',
                },
            },
        },
    };
};

export const ShareDetailsWrapper: StyleRule = ({ theme }) => {
    return {
        display: 'flex',
        paddingTop: pxToRem(18),
        [`& .${checkboxClassName}`]: {
            display: 'flex',
            alignItems: 'center',
            [`& .${checkboxSlotClassNames.indicatorContainer}`]: {
                width: pxToRem(20),
                height: pxToRem(20),
                [`& .${indicatorCheckboxClassName}`]: {
                    width: pxToRem(20),
                    height: pxToRem(20),
                },
                [`& .${svgIconClassName}`]: {
                    width: pxToRem(20),
                    height: pxToRem(20),
                },
            },
            [`& .${captionClassName}`]: {
                color: theme.siteVariables.colors.charcoal[250],
                padding: pxArrayToRem([2, 13]),
                fontSize: pxToRem(16),
                margin: 0,
                letterSpacing: 0,
            },
        },
    };
};

export const ShareDetailsText: StyleRule = ({ theme }) => {
    return {
        [`& .${captionClassName}`]: {
            color: theme.siteVariables.colors.grey[300],
            padding: pxArrayToRem([0, 30, 0, 33]),
            lineHeight: pxToRem(14),
            letterSpacing: 0,
            fontSize: pxToRem(14),
            maxWidth: pxToRem(520),
        },
    };
};

export const BranchDropdown: StyleRule<{
    visibleRowsBranchDropdown: number;
    isMobile: boolean;
}> = ({ visibleRowsBranchDropdown, isMobile, theme }) => {
    return {
        marginTop: pxToRem(10),

        [`& .${textInputSlotClassNames.primaryContent}`]: {
            cursor: 'pointer',
            [`& .${dropdownSlotClassNames.itemsList}`]: {
                cursor: 'pointer',
                paddingTop: 0,
                maxHeight: pxToRem(42 * visibleRowsBranchDropdown),
                ...getScrollbarStyle(theme, '0'),
                width: `calc(100% + ${pxToRem(34)})`,
                [theme.screen.tablet]: {
                    width: `calc(100% + ${pxToRem(48)})`,
                },
            },
            [`& .${dropdownSlotClassNames.item}`]: {
                border: 'none',

                [`& .${dropdownItemSlotClassNames.header}`]: {
                    fontSize: isMobile
                        ? `max(16px, ${pxToRem(16)})`
                        : pxToRem(16),
                },
            },
            [`& .${dropdownSlotClassNames.selectedItems}`]: {
                maxHeight: 'unset',
                [`& > .${dropdownSlotClassNames.triggerButton}`]: {
                    border: 'none',
                    flexGrow: 1,
                    backgroundColor: 'transparent',
                    fontSize: isMobile
                        ? `max(16px, ${pxToRem(16)})`
                        : pxToRem(16),
                    lineHeight: theme.siteVariables.lineHeightSmall,
                    padding: pxArrayToRem([24, 20]),
                },
            },
            [`& > .${boxClassName}`]: {
                paddingBottom: 0,
                [`& .${dropdownClassName}`]: {
                    margin: 0,
                },
                [`& > label`]: {
                    fontSize: isMobile
                        ? `max(16px, ${pxToRem(16)})`
                        : pxToRem(16),
                },
                [`& .${inputSlotClassNames.input}`]: {
                    fontSize: isMobile
                        ? `max(16px, ${pxToRem(16)})`
                        : pxToRem(16),
                    lineHeight: theme.siteVariables.lineHeightSmall,
                    color: theme.siteVariables.colors.charcoal['250'],
                },
            },
        },
    };
};

export const EnquiryGrid: StyleRule = ({ theme }) => {
    return {
        display: 'grid',
        gridTemplateColumns: `1fr`,
        gap: pxToRem(8),
        marginTop: pxToRem(8),

        [theme.screen.tablet]: {
            gridTemplateColumns: `repeat(2, 1fr)`,
        },
    };
};

export const EnquiryForm: StyleRule<{
    isMobile: boolean;
}> = ({ isMobile, theme }) => {
    return {
        [`& .${dividerClassName}`]: {
            display: 'none',
        },
        [`& .${textInputSlotClassNames.message}`]: {
            marginTop: pxToRem(8),
            [`&.${captionClassName}`]: {
                fontSize: pxToRem(14),
            },
        },
        [`& .${textInputSlotClassNames.primaryContent}`]: {
            background: '#F3F3F3',
            borderRadius: pxToRem(4),
            [`& .${inputSlotClassNames.icon}`]: {
                minHeight: 'unset',
                height: 'unset',
            },
            [`& .${inputClassName}`]: {
                minHeight: '100%',
                [`& > .${boxClassName}`]: {
                    paddingBottom: 0,
                    [`& .${inputSlotClassNames.input}`]: {
                        background: '#F3F3F3',
                        fontSize: isMobile
                            ? `max(16px, ${pxToRem(16)})`
                            : pxToRem(16),
                        lineHeight: theme.siteVariables.lineHeightSmall,
                        color: theme.siteVariables.colors.charcoal['250'],
                        [`:disabled`]: {
                            color: theme.siteVariables.colors.grey[300],
                        },
                    },
                },
            },
        },
    };
};

export const EnquiryButtonContainer: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(24),
    marginLeft: 'auto',
    [theme.screen.tablet]: {
        height: pxToRem(66),
        width: pxToRem(197),
    },
});
