import {
    useStyles,
    useTheme,
    Label,
    pxToRem,
    useAppDisplayModeContext,
    Caption,
} from '@archipro-design/aria';
import * as S from './LoginEnquiryInline.style';
import { toSentenceCase } from '~/modules/root';

interface EnquiryHeadingProps {
    headingLabel?: string;
    enquiryMessage?: string;
}

const EnquiryHeading = ({
    headingLabel = 'Enquire now',
    enquiryMessage,
}: EnquiryHeadingProps) => {
    const { mobile } = useAppDisplayModeContext();

    const { css } = useStyles();
    const theme = useTheme();

    const sentanceCaseLabel = toSentenceCase(headingLabel);

    return (
        <>
            <Label
                variant="03"
                weight="regular"
                className={css(S.EnquiryTitle)}
                variables={{
                    letterSpacing: theme.siteVariables.letterSpacingSmall,
                    lineHeight: theme.siteVariables.lineHeightSmaller,
                    fontSize: pxToRem(mobile ? 18 : 24),
                }}
            >
                {sentanceCaseLabel}
            </Label>
            {enquiryMessage ? (
                <Caption
                    variant="03"
                    variables={{
                        lineHeight: theme.siteVariables.lineHeightSmall,
                        fontSize: pxToRem(mobile ? 16 : 18),
                    }}
                    dangerouslySetInnerHTML={{
                        __html: enquiryMessage,
                    }}
                    className={css(S.EnquiryMessage)}
                ></Caption>
            ) : null}
        </>
    );
};

export default EnquiryHeading;
