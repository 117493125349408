import { Flex, useDeviceBrowserContext } from '@archipro-design/aria';
import { useSocialLogins } from '../hook/use-social-logins';
import IconSocialLoginButton from './IconSocialLoginButton';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import RecognisedSocialLoginButton from './RecognisedSocialLoginButton';
import type { AuthSource } from '~/modules/user/type';
interface SocialLoginProps {
    disableSignin?: boolean;
    publicUser?: PublicUser;
    authSource: AuthSource;
    redirect?: string;
    skipPostSignUp: boolean;
}

const InlineSocialLogin = ({
    disableSignin,
    publicUser,
    authSource,
    redirect,
    skipPostSignUp,
}: SocialLoginProps) => {
    const { isInstagramOrFacebookApp } = useDeviceBrowserContext();
    const socialLogins = useSocialLogins({
        loginOptions: publicUser?.socialLoginOptions,
        authSource,
        redirect,
        skipPostSignUp,
        isInstagramOrFacebookApp,
    });

    const renderedLoginButtons = socialLogins.map((social) => {
        return (
            <IconSocialLoginButton
                social={social}
                key={social.KEY}
                disabled={disableSignin}
                authSource={authSource}
            />
        );
    });

    return (
        <Flex vAlign="center" gap="space-8" design={{ width: '100%' }}>
            {socialLogins.length === 1 && socialLogins[0] ? (
                <RecognisedSocialLoginButton
                    social={socialLogins[0]}
                    authSource={authSource}
                />
            ) : (
                renderedLoginButtons
            )}
        </Flex>
    );
};

export default InlineSocialLogin;
