import type { Tracker } from '@archipro-website/tracker';
import type { Directory } from '~/modules/directory/type';
import { getReformatCategoryPath } from './formatHelper';

type BrochureRequestType = Extract<Directory, 'professional' | 'product'>;

interface BrochureRequestProps {
    type: BrochureRequestType;
    region: string;
}

export const trackBrochureRequest = (
    tracker: Tracker,
    props: BrochureRequestProps
) => {
    tracker.log('brochureRequest', {
        url: new URL(window.location.href),

        data: {
            type: props.type,
            category: getReformatCategoryPath(window.location.pathname),
            region: props.region,
        },
    });
};
