import type { DetailedPlace, BasicPlace } from '~/modules/location/type';

// do not use this if possible, always use DetailedPlace
export const mapToBasicPlaces = (
    defaultPlaceData: DetailedPlace[]
): BasicPlace[] =>
    defaultPlaceData.map((place) => {
        return {
            suburb: place?.suburb ?? undefined,
            district: place?.district ?? undefined,
            city: place?.city ?? undefined,
            region: place?.region ?? undefined,
            state: place?.state ?? undefined,
            postCode: place?.postCode ?? undefined,
            country: place?.country,
            fullAddress: place?.fullAddress ?? undefined,
        };
    });
