import type { Directory } from '@modules/directory/type';

export const getEnquiryMessagePlaceholder = (
    professionalTitle: string,
    pageType?: Directory
) => {
    switch (pageType) {
        case 'professional':
            return `Send an enquiry to ${professionalTitle}`;
        default:
            return `Send an enquiry about this ${pageType} to ${professionalTitle}`;
    }
};
