import { Button, pxToRem, useStyles, Loader } from '@archipro-design/aria';
import type { SocialLoginOptions, SocialLoginProvider } from '../type';
import { useState } from 'react';
import * as S from './IconSocialLoginButton.style';
import { useTracker } from '@archipro-website/tracker';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import { trackAuthEvent } from '~/modules/user/util/track-auth-event';
import type { AuthSource } from '~/modules/user/type';

interface RecognisedSocialLoginButtonProps {
    social: SocialLoginOptions;
    disabled?: boolean;
    authSource: AuthSource;
}

const RecognisedSocialLoginButton = ({
    social,
    disabled = false,
    authSource,
}: RecognisedSocialLoginButtonProps) => {
    const { css } = useStyles();
    const [spinner, setSpinner] = useState<SocialLoginProvider | undefined>();
    const tracker = useTracker();

    const { setAutoSubmit } = useEnquirySession();

    const buttonText = `Continue with ${social.TITLE} and send enquiry`;
    return (
        <Button
            as="a"
            href={`/member/${social.URL}`}
            key={social.KEY}
            data-button={social.TRACKER_ID}
            fluid
            size={18}
            color="grey"
            disabled={disabled}
            {...(disabled && {
                className: css(S.DisabledSocialButton),
            })}
            className={css(S.RecognisedSocialButton)}
            iconBefore={social.ICON}
            variables={{
                buttonPadding: pxToRem(20),
                iconPadding: 0,
                buttonRadius: pxToRem(4),
            }}
            onClick={() => {
                setSpinner(social.TITLE);
                setAutoSubmit(true);
                tracker.log('accountLogin', {
                    url: new URL(window.location.href),
                    data: {
                        step: 2,
                        signInMethod: social.TITLE,
                    },
                });

                trackAuthEvent({
                    tracker,
                    event: 'AuthSocialButtonClick',
                    authSource,
                    provider: social.TITLE,
                });
            }}
            {...(spinner === social.TITLE && {
                className: css(S.DisabledSocialButton),
            })}
            {...(spinner === social.TITLE && {
                iconAfter: (
                    <Loader
                        size="smallest"
                        variables={{
                            containerHeights: {
                                smallest: pxToRem(20),
                            },
                            containerWidths: {
                                smallest: pxToRem(20),
                            },
                        }}
                    />
                ),
            })}
        >
            {buttonText}
        </Button>
    );
};

export default RecognisedSocialLoginButton;
