import { z } from 'zod';
import {
    EMAIL_INPUT,
    NAME_INPUT,
    MESSAGE_INPUT,
    PHONE_NUMBER_INPUT,
    PLACE_INPUT,
    FIRST_NAME_INPUT,
} from '~/modules/validation/util/form';
import type { Branch } from '../../util/extract-branch-from-network.server';

interface DropdownItem<T> {
    header: string;
    value: T;
    selected?: boolean;
}

const suburbValidator = z
    .object({
        header: z.string(),
        value: PLACE_INPUT,
    })
    .required();

const branchValidator = z
    .object({
        header: z.string(),
        value: PLACE_INPUT.extend({ branchId: z.number() }),
    })
    .optional();

const enquiryFirstNameValidator = z.object({
    name: FIRST_NAME_INPUT,
});

const enquiryFormValidator = z.object({
    siteTreeId: z.number(),
    name: NAME_INPUT,
    phoneNumber: PHONE_NUMBER_INPUT,
    email: EMAIL_INPUT,
    suburbPostcode: suburbValidator,
    message: MESSAGE_INPUT,
    shareDetails: z.boolean(),
});

const enquiryFormValidatorWithBranch = enquiryFormValidator.extend({
    branch: branchValidator.refine((v) => !!v, {
        message: 'Please select a branch',
    }),
});

export const useEnquiryFormValidator = (
    branches: DropdownItem<Branch>[],
    hasEmptyLastName: boolean
) => {
    const enquiryValidator =
        branches.length > 0
            ? enquiryFormValidatorWithBranch
            : enquiryFormValidator;

    if (hasEmptyLastName) {
        return enquiryValidator.merge(enquiryFirstNameValidator);
    }

    return enquiryValidator;
};

export type EnquiryFormShape = z.infer<typeof enquiryFormValidatorWithBranch>;
