import { Label, Caption, useTheme, useStyles } from '@archipro-design/aria';
import * as S from './LoginEnquiryInline.style';

const EnquirySuccess = () => {
    const theme = useTheme();
    const { css } = useStyles();
    return (
        <div>
            <Label
                variant="01"
                variables={{
                    lineHeight: theme.siteVariables.lineHeightSmall,
                }}
            >
                Enquiry sent!
            </Label>
            <Caption
                variant="04"
                variables={{
                    color: theme.siteVariables.colors.charcoal[100],
                }}
                className={css(S.SuccessCaption)}
            >
                Thanks for getting in touch. You can view your submitted enquiry
                in your{' '}
                <div
                    className={css(S.CaptionLink)}
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                        window.location.href = '/member/inbox';
                    }}
                >
                    messages
                </div>
                .
            </Caption>
        </div>
    );
};

export default EnquirySuccess;
