import type { EnquiryProfessional } from '~/modules/enquiry/component/enquiry-form/EnquiryForm';
import { EnquiryForm } from '~/modules/enquiry/component/enquiry-form/EnquiryForm';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import { useRef, useState } from 'react';
import { usePublicUser } from '~/modules/user/hook/use-public-user';
import type { FavouriteItem } from '~/modules/design-board/type';
import { Caption, useStyles, useTheme } from '@archipro-design/aria';
import * as S from './EnquiryInline.style';
import { useImpression, useTracker } from '@archipro-website/tracker';
import type { EnquiryItemType } from '~/modules/tracking/util/trackEnquireReveal';
import { trackEnquireReveal } from '~/modules/tracking/util/trackEnquireReveal';

interface EnquiryInlineProps {
    professional: EnquiryProfessional;
    disableSubmit?: boolean;
    disableSaveToDesignBoard?: boolean;
    submitButtonText?: string;
    favouriteItem?: FavouriteItem;
    siteTreeID: number;
    messageBodyPlaceholder?: string;
    showCustomEnquiryText?: boolean;
    itemType: EnquiryItemType;
}

export const EnquiryInline = (props: EnquiryInlineProps) => {
    const {
        professional,
        disableSubmit,
        submitButtonText,
        messageBodyPlaceholder,
        favouriteItem,
        disableSaveToDesignBoard,
        siteTreeID,
        showCustomEnquiryText,
        itemType,
    } = props;

    const theme = useTheme();
    const { css } = useStyles();

    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const { publicUser, setPublicUser } = usePublicUser('enquiry');

    const formRef = useRef(null);
    const tracker = useTracker();

    useImpression({
        ref: formRef,
        onImpression: async () => {
            trackEnquireReveal(tracker, {
                professionalName: professional.Title,
                itemID: siteTreeID,
                itemType,
                source: 'InPage',
            });
        },
    });

    return (
        <div ref={formRef}>
            {showCustomEnquiryText && (
                <Caption
                    variant="03"
                    variables={{
                        lineHeight: theme.siteVariables.lineHeightSmall,
                    }}
                    dangerouslySetInnerHTML={{
                        __html: professional.CustomEnquiryMessage ?? '',
                    }}
                    className={css(S.CustomEnquiryMessage)}
                ></Caption>
            )}
            <EnquiryForm
                professional={professional}
                branchOptions={professional.branchOptions ?? []}
                disableSubmit={disableSubmit}
                disableSaveToDesignBoard={disableSaveToDesignBoard}
                onSuccess={() => {
                    setLoginModalOpen(false);
                }}
                onFailure={(publicUser) => {
                    setPublicUser(publicUser);
                    setLoginModalOpen(true);
                }}
                source={'InPage'}
                submitButtonText={submitButtonText}
                messageBodyPlaceholder={messageBodyPlaceholder}
                favouriteItem={favouriteItem}
                siteTreeID={siteTreeID}
            />
            {publicUser && (
                <LoginSignUpModal
                    login={{
                        open: loginModalOpen,
                        onCancel: () => {
                            setLoginModalOpen(false);
                        },
                        onConfirm: () => setLoginModalOpen(false),
                        flow: 'post-enquiry-login',
                        defaultPublicUser: publicUser,
                        authSource: 'enquiry',
                        onAuthSuccess: () => setLoginModalOpen(false),
                    }}
                    forgotPassword={{
                        open: loginModalOpen,
                        onCancel: () => setLoginModalOpen(false),
                        onConfirm: () => setLoginModalOpen(false),
                    }}
                />
            )}
        </div>
    );
};
