import type { StyleRule } from '@archipro-design/aria';
import { buttonClassName, labelClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const SubmitButton: StyleRule = ({ theme }) => {
    return {
        width: '100%',
        [`& .${buttonClassName}`]: {
            marginTop: 0,
            borderRadius: pxToRem(4),
            [`&:disabled`]: {
                background: '#34343433',
                [`& .${labelClassName}`]: {
                    color: theme.siteVariables.colors.primitive.white,
                },
            },
        },
        [theme.screen.tablet]: {
            width: 'auto',
            [`& .${buttonClassName}`]: {
                float: 'right',
                height: pxToRem(66),
                width: '100%',
            },
        },
    };
};
