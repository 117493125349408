import type { Tracker } from '@archipro-website/tracker';
import type { Crumb } from '~/modules/project/util/make-crumbs';

interface TrackProps {
    category: string;
    step: 1 | 2;
    project: string;
    professional: string;
    region?: string;
}

export const trackProjectEnquire = (tracker: Tracker, props: TrackProps) => {
    const { category, step, project, professional, region } = props;

    tracker.log('projectEnquire', {
        url: new URL(window.location.href),
        data: {
            category: category,
            step: step,
            project: project,
            professionals: professional,
            region: region,
        },
    });
};

export const getProjectCategoryFromBreadcrumbs = (
    breadcrumbs: Crumb[]
): string => {
    if (breadcrumbs.length <= 1) {
        // exclude the Projects breadcrumb
        return '';
    }

    return breadcrumbs
        .slice(1)
        .map((breadcrumb) => breadcrumb.label)
        .join('/');
};
