import type { StyleRule } from '@archipro-design/aria';
import {
    captionClassName,
    textInputSlotClassNames,
    dividerClassName,
    inputClassName,
    boxClassName,
    inputSlotClassNames,
    pxToRem,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import type { LoginFlow } from '~/modules/user/hook/use-login-flow';

export const LoginSignupForm: StyleRule = ({ theme }) => ({
    width: '100%',
    [`& .${dividerClassName}`]: {
        display: 'none',
    },
    [`& .${textInputSlotClassNames.message}`]: {
        marginTop: pxToRem(8),
        [`&.${captionClassName}`]: {
            fontSize: pxToRem(14),
        },
    },
    [`& .${textInputSlotClassNames.primaryContent}`]: {
        background: '#F3F3F3',
        borderRadius: pxToRem(4),
        [`& .${inputSlotClassNames.icon}`]: {
            height: 'unset',
        },
        [`& .${inputClassName}`]: {
            minHeight: 'unset',
            [`& > .${boxClassName}`]: {
                paddingBottom: 0,
                [`& .${inputSlotClassNames.input}`]: {
                    background: '#F3F3F3',
                    fontSize: `max(16px, ${pxToRem(16)})`,
                    [theme.screen.tablet]: {
                        fontSize: pxToRem(16),
                    },
                    lineHeight: theme.siteVariables.lineHeightSmall,
                    color: theme.siteVariables.colors.charcoal['250'],
                    [`:disabled`]: {
                        color: theme.siteVariables.colors.grey[300],
                    },
                },
            },
        },
    },
});

export const LoginFormGrid: StyleRule = ({ theme, flow }) => {
    const baseStyling = {
        gridGap: pxToRem(8),
        gridTemplateRows: 'auto',
    };
    switch (flow) {
        case undefined:
        case null:
            return {
                ...baseStyling,
                gridTemplateAreas: `"email" 
                    "submit"`,
                [theme.screen.tablet]: {
                    gridTemplateAreas: `"email submit"`,
                },
            };
        case 'login':
            return {
                ...baseStyling,
                gridTemplateAreas: `"email" 
                    "password" 
                    "submit"`,
                [theme.screen.tablet]: {
                    gridTemplateAreas: `"email password" "submit submit"`,
                },
            };
        case 'sign-up':
            return {
                ...baseStyling,
                gridTemplateAreas: `"email" "password" "firstName" "lastName" "submit"`,
                [theme.screen.tablet]: {
                    gridTemplateAreas: `"email password" "firstName lastName" "submit submit"`,
                },
            };
        default:
            return {};
    }
};

export const ButtonContainer: StyleRule = ({
    flow,
    loginErrorMessage,
    signUpErrorMessage,
}) => {
    const baseButtonContainerStyles = {
        gridArea: 'submit',
    };
    switch (flow) {
        case 'login':
        case 'sign-up':
            return {
                ...baseButtonContainerStyles,
                marginTop:
                    loginErrorMessage || signUpErrorMessage
                        ? pxToRem(2)
                        : pxToRem(24),
            };
        default:
            return {
                ...baseButtonContainerStyles,
            };
    }
};

export const calculateGridColumns = (isMobile: boolean, flow?: LoginFlow) => {
    if (isMobile) {
        return '1fr';
    } else {
        switch (flow) {
            case undefined:
            case null:
                return '2fr 1fr';
            case 'login':
            case 'sign-up':
            default:
                return '1fr 1fr';
        }
    }
};

export const PasswordFieldStyling: StyleRule = ({ theme }) => ({
    [`& .${inputSlotClassNames.icon}.${inputSlotClassNames.icon}`]: {
        height: pxToRem(68),
        [theme.screen.tablet]: {
            marginRight: pxToRem(8),
        },
    },
    [`& .${svgIconClassName}.${svgIconClassName}.${svgIconClassName}`]: {
        width: pxToRem(18),
        height: pxToRem(18),
    },
});

export const EmailInput: StyleRule = () => ({
    [`& input`]: {
        textTransform: 'lowercase',
        '&::placeholder': {
            textTransform: 'none',
        },
    },
});
