import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const AwardBadgesContainer: StyleRule = ({ theme }) => ({
    width: '100%',
    padding: pxArrayToRem([0, 22]),
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.siteVariables.zIndexes.menuItem,
    [theme.screen.tablet]: {
        padding: pxArrayToRem([0, 40]),
        height: pxToRem(300),
    },
});

export const ActiveHover: StyleRule<{ showAwards: boolean }> = ({
    showAwards,
}) => ({
    width: '100%',
    maxWidth: showAwards ? '100%' : pxToRem(60),
    transition: 'max-width 200ms linear',
});

export const AwardSlider: StyleRule<{ showAwards: boolean }> = ({
    showAwards,
}) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    opacity: showAwards ? 1 : 0,
    transition: 'opacity 200ms linear 100ms',
    margin: pxArrayToRem([37, 8]),
});

export const AwardSliderNavigation: StyleRule = () => ({
    cursor: 'pointer',
    height: pxToRem(80),
    minWidth: pxToRem(80),
    width: pxToRem(80),
    borderRadius: '50%',
    backgroundColor: '#F3F3F3',
    marginLeft: pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.swiper-button-disabled': {
        display: 'none',
    },
});

export const SwiperContainer: StyleRule<{ awardsPerSlide: number }> = ({
    awardsPerSlide,
}) => ({
    maxWidth: pxToRem(96 * awardsPerSlide), //badge width + gap
    width: '100%',
});

export const Swiper: StyleRule = () => ({
    width: '100%',
});

export const BadgeSpacer: StyleRule = () => ({
    height: pxToRem(90),
    width: pxToRem(107),
});

export const FilterLinks: StyleRule = ({ theme }) => ({
    minWidth: pxToRem(260),
    padding: pxArrayToRem([16, 12, 0, 4]),
    [theme.screen.tablet]: {
        paddingTop: pxToRem(20),
    },
});

export const FilterLink: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,

    fontSize: theme.siteVariables.fontSizes.caption04,
    display: 'flex',
    alignItems: 'center',
    marginBottom: pxToRem(2),
    color: theme.siteVariables.colors.charcoal['000'],

    [`& .${svgIconClassName}`]: {
        marginLeft: pxToRem(2),
        '& > svg': {
            '& > path': {
                fill: theme.siteVariables.colors.charcoal['000'],
            },
        },
    },

    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        color: theme.siteVariables.colors.grey[400],
        '&:hover': {
            textDecoration: 'underline !important',
            textUnderlineOffset: '2px',
        },
        [`& .${svgIconClassName}`]: {
            '& > svg': {
                '& > path': {
                    fill: theme.siteVariables.colors.grey[400],
                },
            },
        },
    },
});

export const Badge: StyleRule = () => ({
    position: 'relative',
    cursor: 'pointer',
    height: pxToRem(80),
    width: pxToRem(80),
    minWidth: pxToRem(80),
    borderRadius: '50%',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    margin: pxArrayToRem([0, 16]),
});

export const BadgeInfo: StyleRule = ({ theme }) => ({
    minWidth: pxToRem(183),
    color: theme.siteVariables.colors.white['FFF'],
    zIndex: theme.siteVariables.zIndexes.menuItem + 1,
});

export const BadgeTitle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Bold75,
    fontSize: theme.siteVariables.fontSizes.caption04,
    color: theme.siteVariables.colors.charcoal['000'],
    margin: pxArrayToRem([5, 3, 0, 3]),
    [theme.screen.tablet]: {
        fontSize: theme.siteVariables.fontSizes.caption03,
        color: theme.siteVariables.colors.primitive.white,
        lineHeight: '120%',
        margin: 0,
    },
});

export const BannerInfoWrapper: StyleRule = () => ({
    width: '100%',
    wordWrap: 'break-word',
    overflow: 'hidden',
    padding: pxToRem(18),
});

export const BadgeSubtitle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    fontSize: theme.siteVariables.fontSizes.caption04,
    color: theme.siteVariables.colors.charcoal['000'],
    margin: pxArrayToRem([0, 3]),
    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        color: theme.siteVariables.colors.primitive.white,
        lineHeight: '110%',
        margin: pxArrayToRem([8, 0, 0, 0]),
    },
});

export const BadgeYear: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.grey[400],
    fontSize: theme.siteVariables.fontSizes.caption04,
    margin: pxArrayToRem([0, 3]),
    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        color: theme.siteVariables.colors.grey[400],
        lineHeight: '110%',
        margin: pxArrayToRem([8, 0, 0, 0]),
    },
});

export const AwardDrawer: StyleRule = () => ({
    margin: pxArrayToRem([30, 0]),
    gap: pxToRem(30),
});

export const AwardDrawerInfo: StyleRule = () => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
});
