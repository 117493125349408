import type { z } from 'zod';
import { useLoginFlow } from '~/modules/user/hook/use-login-flow';
import type { LoginFlow, signUpValidator } from '../../../hook/use-login-flow';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import type { LoginAction } from '~/routes/remix-api.user.login';
import type { SignUpAction } from '~/routes/remix-api.user.sign-up';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';
import { useTracker } from '@archipro-website/tracker';
import ExpandableContainer from '../../expandable-component/ExpandableContainer';
import {
    Grid,
    TextInput,
    pxArrayToRem,
    useAppDisplayModeContext,
    useStyles,
} from '@archipro-design/aria';
import * as S from './InlineLoginSignUpForm.style';
import { useFetcher } from '@remix-run/react';
import EnquiryButton from '~/modules/enquiry/component/login-enquiry-inline/enquiry-button/EnquiryButton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EMAIL_REGEX } from '~/modules/validation/util';
import {
    assertErrorResponse,
    assertSuccessResponse,
} from '@modules/root/graphql/responses';

import { EyeLine, EyeOffLine } from '@archipro-design/icons';
import { useABTestValue } from '~/modules/root/hook/use-growthbook';
import type { AuthSource } from '~/modules/user/type';
import { useUser } from '~/modules/user/hook';
import { useAuth } from '~/modules/root/context';
import { trackAuthEvent } from '~/modules/user/util/track-auth-event';

type FormShape = z.infer<typeof signUpValidator>;

interface InlineLoginSignUpFormProps {
    flow: LoginFlow;
    onEmailBlur?: (email: string) => void;
    onSubmitButtonClick?: (email: string) => void;
    defaultPublicUser?: PublicUser;
    isEnquiryValid: boolean;
    authSource: AuthSource;
}

const INPUT_DEFAULT_VARIABLES = {
    inputMarginLargeDesktop: pxArrayToRem([22, 20]),
    inputMarginMediumDesktop: pxArrayToRem([22, 20]),
    inputMarginMobile: pxArrayToRem([14, 13]),
    hoverBackgroundColor: 'transparent',
};

const InlineLoginSignUpForm = ({
    flow,
    onEmailBlur,
    onSubmitButtonClick,
    defaultPublicUser,
    isEnquiryValid,
    authSource,
}: InlineLoginSignUpFormProps) => {
    const tracker = useTracker();
    const { mobile } = useAppDisplayModeContext();
    const { formValidator, displayFormSegments, defaultFormValues } =
        useLoginFlow(flow);

    const { emailSegment, passwordSegment, firstNameSegment, lastNameSegment } =
        displayFormSegments;

    const { enquirySession, setEnquirySession, setAutoSubmit } =
        useEnquirySession();

    const defaultValues = defaultFormValues(enquirySession, defaultPublicUser);
    const enquiryVariantControl = useABTestValue('upgraded-enquiry-flow');

    const loginFetcher = useFetcher<LoginAction>();
    const signupFetcher = useFetcher<SignUpAction>();

    const endRef = useRef<HTMLDivElement>(null);

    const [emailValid, setEmailValid] = useState(false);
    const [passwordVisible, setPasswordVisibility] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const { authRedirect } = useAuth();
    const user = useUser();

    const {
        control,
        handleSubmit,
        getValues,
        watch,
        getFieldState,
        formState: { errors },
    } = useForm<FormShape>({
        resolver: zodResolver(formValidator()),
        defaultValues,
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    const loginErrorMessage =
        (loginFetcher.data &&
            assertErrorResponse(loginFetcher.data) &&
            loginFetcher.data.message) ||
        undefined;

    const signUpErrorMessage = errors.firstName || errors.lastName;

    const { css } = useStyles({
        flow,
        loginErrorMessage,
        signUpErrorMessage,
    });

    const renderPasswordMessage = () => {
        return {
            dangerouslySetInnerHTML: {
                __html: loginErrorMessage,
            },
            ...(loginErrorMessage?.includes('reset your password') && {
                onClick: () => {
                    window.location.href = '/forgot-password';
                },
            }),
        };
    };

    const renderPasswordIcon = () => {
        if (passwordVisible) {
            return (
                <EyeOffLine
                    key="visible"
                    onClick={() => setPasswordVisibility(!passwordVisible)}
                />
            );
        }
        return (
            <EyeLine
                key="invisible"
                onClick={() => setPasswordVisibility(!passwordVisible)}
            />
        );
    };

    const watchEmail = watch('email');
    const watchPassword = watch('password');
    const watchFirstName = watch('firstName');
    const watchLastName = watch('lastName');
    const passwordTouched = getFieldState('password').isTouched;

    React.useEffect(() => {
        setEmailValid(EMAIL_REGEX.test(watchEmail ?? ''));
    }, [watchEmail]);

    React.useEffect(() => {
        setDisableButton(() => {
            switch (flow) {
                case 'login':
                    return !watchPassword;
                case 'sign-up':
                    return !watchPassword || !watchFirstName || !watchLastName;
                default:
                    return false;
            }
        });
    }, [flow, setDisableButton, watchFirstName, watchLastName, watchPassword]);

    const buttonDisabled = !isEnquiryValid || !emailValid || disableButton;

    useEffect(() => {
        if (
            assertSuccessResponse(signupFetcher.data) &&
            user.__typename === 'Me'
        ) {
            // InlineLoginSignUpForm is used for on page enquiry, should skip PostSignUp
            authRedirect({
                authSource,
                provider: 'EmailPassword',
                authType: 'sign-up',
                skipPostSignUp: true,
            });
        }
    }, [signupFetcher.data, user, authRedirect, authSource]);

    useEffect(() => {
        if (
            loginFetcher.data &&
            assertSuccessResponse(loginFetcher.data) &&
            user.__typename === 'Me'
        ) {
            // InlineLoginSignUpForm is used for on page enquiry, should skip PostSignUp
            authRedirect({
                authSource,
                provider: 'EmailPassword',
                authType: 'sign-in',
                skipPostSignUp: true,
            });
        }
    }, [loginFetcher.data, user, authRedirect, authSource]);

    const [submitButtonText, setSubmitButtonText] = useState('Continue');

    const generateSubmitButtonText = useCallback(() => {
        switch (flow) {
            case 'login':
                return 'LOGIN & SEND ENQUIRY';
            case 'sign-up':
                return 'SIGN UP & SEND ENQUIRY';
            default:
                return 'CONTINUE';
        }
    }, [flow]);

    useEffect(() => {
        if (flow || enquiryVariantControl === 'hidden') {
            endRef.current?.scrollIntoView?.({
                block: 'center',
                behavior: 'smooth',
            });
        }

        setSubmitButtonText(generateSubmitButtonText());
    }, [flow, enquiryVariantControl, generateSubmitButtonText]);

    const onSubmit: SubmitHandler<FormShape> = (data) => {
        if (
            !flow ||
            loginFetcher.state !== 'idle' ||
            signupFetcher.state !== 'idle'
        ) {
            return;
        }

        setAutoSubmit(true);

        if (flow === 'login' || flow === 'post-enquiry-login') {
            setEnquirySession({ ...enquirySession, email: getValues('email') });
            tracker.log('accountLogin', {
                data: {
                    step: 2,
                    signInMethod: 'Email',
                },
                url: new URL(window.location.href),
            });

            trackAuthEvent({
                tracker,
                event: 'AuthSignInSubmit',
                authSource,
                provider: 'EmailPassword',
            });

            loginFetcher.submit(data, {
                method: 'post',
                action: `/remix-api/user/login`,
            });
        }

        if (flow === 'sign-up' || flow === 'post-enquiry-sign-up') {
            trackAuthEvent({
                tracker,
                event: 'AuthSignUpSubmit',
                authSource,
                provider: 'EmailPassword',
            });

            signupFetcher.submit(data, {
                method: 'post',
                action: `/remix-api/user/sign-up`,
            });
        }
    };

    const handleEmailBlur = (email: string) => {
        if (email) {
            trackAuthEvent({
                tracker,
                event: 'AuthEmailInput',
                authSource,
                provider: 'EmailPassword',
            });
        }
        onEmailBlur?.(email);
    };

    return (
        <loginFetcher.Form
            id="inline-login-signup-form"
            onSubmit={handleSubmit(onSubmit)}
            className={css(S.LoginSignupForm)}
            noValidate={true}
        >
            <Grid
                className={css(S.LoginFormGrid)}
                columns={S.calculateGridColumns(mobile, flow)}
            >
                <ExpandableContainer
                    visible={emailSegment ?? false}
                    style={{ gridArea: 'email' }}
                >
                    <Controller<FormShape>
                        control={control}
                        name="email"
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                state={error?.message ? 'error' : 'default'}
                                message={error?.message}
                                variables={INPUT_DEFAULT_VARIABLES}
                                className={css(S.EmailInput)}
                                input={{
                                    ...field,
                                    placeholder: 'Email*',
                                    autoComplete: 'email',
                                    required: true,
                                    autoCapitalize: 'off',
                                    onBlur: async (e) => {
                                        field.onBlur?.();
                                        if (
                                            e.relatedTarget?.id ===
                                            'forgot-password-button'
                                        ) {
                                            return;
                                        }
                                        handleEmailBlur(field.value);
                                    },
                                    onKeyDown: (e) => {
                                        if (e.key === 'Enter') {
                                            handleEmailBlur(field.value);
                                        }
                                    },
                                    input: {
                                        className: 'data-hj-allow',
                                    },
                                }}
                            />
                        )}
                    />
                </ExpandableContainer>
                <ExpandableContainer
                    visible={passwordSegment ?? false}
                    style={{ gridArea: 'password' }}
                >
                    <Controller<FormShape>
                        control={control}
                        name="password"
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                state={
                                    loginErrorMessage ||
                                    (passwordTouched && error?.message)
                                        ? 'error'
                                        : 'default'
                                }
                                message={
                                    passwordTouched
                                        ? loginErrorMessage
                                            ? renderPasswordMessage()
                                            : error?.message
                                        : undefined
                                }
                                variables={INPUT_DEFAULT_VARIABLES}
                                input={{
                                    ...field,
                                    placeholder: 'Enter password',
                                    autoComplete: 'current-password',
                                    required: true,
                                    input: {
                                        type: passwordVisible
                                            ? 'text'
                                            : 'password',
                                    },
                                }}
                                className={css(S.PasswordFieldStyling)}
                                suffixIcons={[renderPasswordIcon()]}
                            />
                        )}
                    />
                </ExpandableContainer>
                <ExpandableContainer
                    visible={firstNameSegment ?? false}
                    style={{ gridArea: 'firstName' }}
                >
                    <Controller<FormShape>
                        control={control}
                        name="firstName"
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                state={error?.message ? 'error' : 'default'}
                                message={error?.message}
                                variables={INPUT_DEFAULT_VARIABLES}
                                input={{
                                    ...field,
                                    placeholder: 'First name',
                                    required: true,
                                    className: 'data-hj-allow',
                                }}
                            />
                        )}
                    />
                </ExpandableContainer>
                <ExpandableContainer
                    visible={lastNameSegment ?? false}
                    style={{ gridArea: 'lastName' }}
                >
                    <Controller<FormShape>
                        control={control}
                        name="lastName"
                        render={({ field, fieldState: { error } }) => (
                            <TextInput
                                state={error?.message ? 'error' : 'default'}
                                message={error?.message}
                                variables={INPUT_DEFAULT_VARIABLES}
                                input={{
                                    ...field,
                                    placeholder: 'Last name',
                                    required: true,
                                    className: 'data-hj-allow',
                                }}
                            />
                        )}
                    />
                </ExpandableContainer>

                <div ref={endRef} className={css(S.ButtonContainer)}>
                    <EnquiryButton
                        onClick={() => {
                            onSubmitButtonClick &&
                                onSubmitButtonClick(getValues('email'));
                        }}
                        buttonText={submitButtonText}
                        disabled={buttonDisabled}
                        loading={
                            loginFetcher.state === 'submitting' ||
                            loginFetcher.state === 'loading' ||
                            signupFetcher.state === 'submitting' ||
                            signupFetcher.state === 'loading'
                        }
                        buttonKey="inline-login-signup-form-submit"
                        type="submit"
                    />
                </div>
            </Grid>
        </loginFetcher.Form>
    );
};

export default InlineLoginSignUpForm;
