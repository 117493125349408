import type { AwardEntityProps } from '@modules/page-builder/component/detail/banner-awards/types';

const DISPLAYED_AWARD_ITEM_TYPES = ['Project', 'Product'];

export interface AwardFilterLink {
    itemType: string;
    link: string;
    title?: string;
    label: string;
}

export const filterAwardEntities = (
    data: AwardEntityProps,
    baseUrl: string,
    awardTitle: string,
    instituteTitle?: string,
    year?: string
): AwardFilterLink[] => {
    return data.edges
        .filter((edge) =>
            DISPLAYED_AWARD_ITEM_TYPES.includes(edge.node.__typename)
        )
        .reduce((accumulator: AwardFilterLink[], edge) => {
            const tabSegment =
                edge.node.__typename === 'Product'
                    ? 'our-products'
                    : edge.node.__typename === 'Project'
                    ? 'case-studies'
                    : '';
            const institutePrefix = instituteTitle
                ? instituteTitle
                      .replace(/[^a-zA-Z0-9\s]/g, '')
                      .toLowerCase()
                      .split(' ')
                      .join('+') + '-'
                : '';
            const awardTitleSegment = awardTitle
                .replace(/[^a-zA-Z0-9\s]/g, '')
                .toLowerCase()
                .split(' ')
                .join('+');
            const yearPostfix = year ? '+' + year : '';
            const awardSegment = `${institutePrefix}${awardTitleSegment}${yearPostfix}`;

            const groupped = accumulator.find(
                (item) => item.itemType === edge.node.__typename
            );

            if (groupped) {
                groupped.title = undefined;
                groupped.label = `View ${edge.node.__typename}s`;
                groupped.link = `${baseUrl}/${tabSegment}?awards=${awardSegment}`;
            } else {
                accumulator.push({
                    title: edge.node.Title,
                    itemType: edge.node.__typename,
                    label: `View ${edge.node.__typename}`,
                    link: edge.node.Link,
                });
            }
            return accumulator;
        }, [])
        .sort((a, b) => {
            return (
                DISPLAYED_AWARD_ITEM_TYPES.indexOf(a.itemType) -
                DISPLAYED_AWARD_ITEM_TYPES.indexOf(b.itemType)
            );
        });
};
