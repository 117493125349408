import { pxToRem } from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

export const LoginSignupContainer: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(24),
    [theme.screen.tablet]: {
        marginTop: pxToRem(32),
    },
});

export const ButtonContainer: StyleRule = () => ({
    width: '100%',
    marginTop: pxToRem(24),
});

export const UseEmailText: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[100],
});

export const ForgotPasswordButton: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.charcoal[100],
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'center',
});
