import UpgradedEnquiryForm from '../enquiry-form-v2/UpgradedEnquiryForm';
import { useEffect, useRef, useState } from 'react';
import type { EnquiryProfessional } from '../enquiry-form/EnquiryForm';
import EnquiryHeading from './EnquiryHeading';
import EnquirySuccess from './EnquirySuccess';
import EnquiryLoginSignup from './login-section/EnquiryLoginSignup';
import { useStyles } from '@archipro-design/aria';
import * as S from './LoginEnquiryInline.style';
import { useAppData } from '~/modules/root';
import { useControllableValue, useLatest } from 'ahooks';
import { useEquiryGetAndHandleIsLoggedIn } from '../../hook/use-enquiry-get-and-handle-is-logged-in';
import type { CreateEnquirySource } from '..';
import {
    trackEnquireReveal,
    type EnquiryItemType,
} from '~/modules/tracking/util/trackEnquireReveal';
import { useImpression, useTracker } from '@archipro-website/tracker';
interface LoginEnquiryInlineProps {
    professional: EnquiryProfessional;
    enquiryVariantControl?: string;
    disableSubmit?: boolean;
    submitButtonText?: string;
    siteTreeID?: number;
    messageBodyPlaceholder?: string;
    showCustomEnquiryText?: boolean;
    isEnquiryModal?: boolean;
    onSuccess?: () => void;
    source?: CreateEnquirySource;
    itemType: EnquiryItemType;
}

export const LoginEnquiryInline = (props: LoginEnquiryInlineProps) => {
    const {
        professional,
        enquiryVariantControl,
        disableSubmit,
        submitButtonText = 'SEND ENQUIRY',
        messageBodyPlaceholder,
        siteTreeID,
        isEnquiryModal = false,
        onSuccess,
        showCustomEnquiryText,
        source = 'InPage',
        itemType,
    } = props;

    const flowObject = useControllableValue();
    const flow = flowObject[0];
    const isLoggedIn = useEquiryGetAndHandleIsLoggedIn(flow, onSuccess);

    const { css } = useStyles({ flow });
    const tracker = useTracker();
    const { autoSubmitSuccess } = useAppData();

    const checkID = siteTreeID ?? professional.ID;

    const disableSubmitButton = disableSubmit || !isLoggedIn;

    const [enquirySubmitted, setEnquirySubmitted] = useState(false);
    const [enquiryValid, setEnquiryValid] = useState(false);

    useEffect(() => {
        setEnquirySubmitted(autoSubmitSuccess === checkID && isLoggedIn);
    }, [autoSubmitSuccess, checkID, isLoggedIn]);

    const onEnquiryChange = (isValid: boolean) => {
        setEnquiryValid(isValid);
    };

    const stableOnSuccess = useLatest(onSuccess);

    const formRef = useRef(null);
    useImpression({
        ref: formRef,
        onImpression: async () => {
            if (source !== 'InPage') return;
            trackEnquireReveal(tracker, {
                professionalName: professional.Title,
                itemID: siteTreeID ?? professional.ID,
                itemType,
                source: 'InPage',
            });
        },
    });

    return (
        <div
            className={
                isEnquiryModal ? css(S.EnquiryModalOverrides) : undefined
            }
            ref={formRef}
        >
            {!enquirySubmitted ? (
                <>
                    <EnquiryHeading
                        headingLabel={professional.primaryCTA?.primaryCTALabel}
                        enquiryMessage={
                            showCustomEnquiryText &&
                            professional.CustomEnquiryMessage
                                ? professional.CustomEnquiryMessage
                                : ''
                        }
                    />
                    <UpgradedEnquiryForm
                        professional={professional}
                        branchOptions={professional.branchOptions ?? []}
                        source={source}
                        messageBodyPlaceholder={messageBodyPlaceholder}
                        siteTreeID={siteTreeID}
                        onSuccess={() => {
                            setEnquirySubmitted(true);
                            stableOnSuccess.current?.();
                        }}
                        disableButton={disableSubmitButton}
                        submitButtonText={submitButtonText}
                        hideButton={!isLoggedIn}
                        onEnquiryChange={onEnquiryChange}
                        flowProps={flowObject}
                    />
                    {!isLoggedIn && (
                        <EnquiryLoginSignup
                            flowProps={flowObject}
                            enquiryVariantControl={enquiryVariantControl}
                            isEnquiryValid={enquiryValid}
                        />
                    )}
                </>
            ) : (
                <EnquirySuccess />
            )}
        </div>
    );
};
