import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import React, { useState } from 'react';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';
import * as S from './BannerAwards.style';
import AwardBadge from '@modules/page-builder/component/detail/banner-awards/AwardBadge';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArrowLeftLine, ArrowRightLine } from '@archipro-design/icons';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import classnames from 'classnames';
import { Flex } from '@archipro-design/aria';
import BottomDrawer from '@modules/detail/components/bottom-drawer/BottomDrawer';
import AwardDrawerItem from '@modules/page-builder/component/detail/banner-awards/AwardDrawerItem';
import AwardIndicator from '@modules/page-builder/component/detail/banner-awards/AwardIndicator';
import { useMatchesHandleData } from '@modules/root';
import { useTracker } from '../../../../../../../tracker/src/react-bindings';

interface BannerAwardsProps {
    awards: AwardProps[];
    heading?: string;
    awardsPerSlide?: number;
    professionalUrl?: string;
}

SwiperCore.use([Navigation]);

const BannerAwards = ({
    awards,
    heading = 'Award winning project',
    awardsPerSlide = 15,
    professionalUrl,
}: BannerAwardsProps) => {
    const [showAwards, setShowAwards] = useState(false);
    const { css } = useStyles({ showAwards, awardsPerSlide });
    const { desktop } = useAppDisplayModeContext();
    const tracker = useTracker();

    const isBMPreview = useMatchesHandleData('isBMPreview', false);

    const onMouseEnter = () => {
        if (isBMPreview) {
            return;
        }
        tracker.log('AwardsHover', {
            url: new URL(window.location.href),
            targetTracker: 'archiproTracker',
        });
        setShowAwards(true);
    };

    const onMouseLeave = () => {
        setShowAwards(false);
    };

    return (
        <div
            className={css(S.AwardBadgesContainer)}
            onMouseLeave={onMouseLeave}
        >
            <Flex className={css(S.ActiveHover)}>
                <div onMouseEnter={onMouseEnter}>
                    <AwardIndicator toggled={showAwards} />
                </div>
                {desktop ? (
                    <Flex
                        className={classnames(
                            css(S.AwardSlider),
                            'awards-slider'
                        )}
                    >
                        <div
                            className={classnames(
                                css(S.AwardSliderNavigation),
                                'awards-slider-button-prev'
                            )}
                        >
                            <ArrowLeftLine />
                        </div>
                        <div className={css(S.SwiperContainer)}>
                            <Swiper
                                slidesPerView={awardsPerSlide}
                                slidesPerGroup={awardsPerSlide}
                                spaceBetween={0} //not using px
                                navigation={{
                                    nextEl: `.awards-slider-button-next`,
                                    prevEl: `.awards-slider-button-prev`,
                                }}
                                allowSlideNext
                                allowSlidePrev
                                allowTouchMove
                                className={css(S.Swiper)}
                            >
                                {awards.map((award, i) => (
                                    <SwiperSlide
                                        key={`awards-badge-slide-${i}`}
                                    >
                                        <AwardBadge
                                            {...award}
                                            professionalUrl={professionalUrl}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                        <div
                            className={classnames(
                                css(S.AwardSliderNavigation),
                                'awards-slider-button-next'
                            )}
                        >
                            <ArrowRightLine />
                        </div>
                    </Flex>
                ) : (
                    <BottomDrawer
                        open={showAwards}
                        toggleModal={(show) => setShowAwards(show)}
                        title={heading}
                    >
                        <Flex column className={css(S.AwardDrawer)}>
                            {awards.map((award, i) => (
                                <AwardDrawerItem
                                    key={`awards-badge-drawer-item-${i}`}
                                    {...award}
                                    toggleDrawer={() => setShowAwards(false)}
                                    professionalUrl={professionalUrl}
                                />
                            ))}
                        </Flex>
                    </BottomDrawer>
                )}
            </Flex>
        </div>
    );
};

export default BannerAwards;
