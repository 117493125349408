import type { Tracker } from '@archipro-website/tracker';
import type { CreateEnquirySource } from '~/modules/enquiry';

export type EnquiryItemType =
    | 'professional'
    | 'product'
    | 'project'
    | 'article';

interface TrackProps {
    professionalName: string;
    itemID: number;
    itemType: EnquiryItemType;
    source: CreateEnquirySource;
}

export const trackEnquireReveal = (tracker: Tracker, props: TrackProps) => {
    tracker.log('EnquiryReveal', {
        url: new URL(window.location.href),
        label: props.professionalName,
        data: {
            Source: props.source,
            content_ids: [`${props.itemType}_${props.itemID}`],
            content_type: props.itemType,
        },
        targetTracker: ['archiproTracker', 'ga4Tracker'],
    });
};

export const trackBookConsultationReveal = (
    tracker: Tracker,
    props: TrackProps
) => {
    tracker.log('BookConsultationReveal', {
        url: new URL(window.location.href),
        label: props.professionalName,
        data: {
            ElementID: 'detail_header_bookConsultation',
            Source: 'detail',
            content_ids: [`${props.itemType}_${props.itemID}`],
            content_type: props.itemType,
        },
    });
};
