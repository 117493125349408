import type { StyleRule } from '@archipro-design/aria';
import { labelClassName, pxArrayToRem, pxToRem } from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';

export const DisabledSocialButton: StyleRule = () => ({
    pointerEvents: 'none',
    cursor: 'not-allowed',
    filter: 'grayscale(100%)',
});

export const RecognisedSocialButton: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([20, 10]),
    [theme.screen.tablet]: {
        padding: pxToRem(20),
    },
    [`& .${svgIconClassName}.${svgIconClassName}`]: {
        width: `${pxToRem(20)}`,
        height: `${pxToRem(20)}`,
        marginRight: pxToRem(10),
        [theme.screen.tablet]: {
            width: pxToRem(24),
            height: pxToRem(24),
            marginRight: pxToRem(13),
        },
    },
    [`& .${labelClassName}.${labelClassName}`]: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(16),
        [theme.screen.tablet]: {
            fontSize: pxToRem(18),
            lineHeight: pxToRem(18),
        },
    },
});
