import {
    MessageTooltip,
    Flex,
    useStyles,
    pxToRem,
} from '@archipro-design/aria';
import React, { useState } from 'react';
import { Image } from '@archipro-design/aria';
import * as S from './BannerAwards.style';
import type { AwardProps } from '@modules/page-builder/component/detail/banner-awards/types';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import type { AwardFilterLink } from '@modules/page-builder/util/map-award-entity-links';
import { filterAwardEntities } from '@modules/page-builder/util/map-award-entity-links';
import { Link } from '@remix-run/react';
import { getCustomPlaceholderColor } from '@modules/elemental-page/util/get-custom-placeholder-color';
import { ChevronRight } from '@archipro-design/icons';
import { useTracker } from '../../../../../../../tracker/src/react-bindings';
import { useDebounceFn } from 'ahooks';

export const PLACEHOLDER_AWARD_BADGE = `/assets/website/images/page-builder/award-badge-placeholder.png`;

export interface AwardBadgeProps extends AwardProps {
    professionalUrl?: string;
    toggleDrawer?: () => void;
}

const AwardBadge = ({
    title,
    institution,
    year,
    badgeThumbUrl,
    awardedEntities,
    professionalUrl,
}: AwardBadgeProps) => {
    const { css } = useStyles();
    const config = useConfigContext();
    const tracker = useTracker();
    const badgeThumb = badgeThumbUrl
        ? badgeThumbUrl
        : `${config.assetsBaseUrl}${PLACEHOLDER_AWARD_BADGE}`;
    const [badgeHovered, setBadgeHovered] = useState(false);
    const [infoHovered, setInfoHovered] = useState(false);
    const infoRef = React.useRef<HTMLDivElement>(null);
    const debouncedSetBadgeHovered = useDebounceFn(
        (infoHovered) => {
            if (infoHovered) {
                return;
            }
            setBadgeHovered(false);
        },
        { wait: 100 }
    );

    const entities: AwardFilterLink[] =
        professionalUrl && awardedEntities
            ? filterAwardEntities(
                  awardedEntities,
                  professionalUrl,
                  title,
                  institution,
                  year
              )
            : [];

    const badge = (
        <div className={css(S.Badge)}>
            <Image
                src={badgeThumb}
                alt={title}
                width={'100%'}
                height={'100%'}
                layout={'fill'}
                objectFit={'cover'}
                {...getCustomPlaceholderColor('light')}
            />
        </div>
    );

    const info = (
        <div
            ref={infoRef}
            className={css(S.BannerInfoWrapper)}
            onMouseEnter={() => setInfoHovered(true)}
            onMouseLeave={() => setInfoHovered(false)}
        >
            <p className={css(S.BadgeTitle)}>{title}</p>
            {!!institution && (
                <p className={css(S.BadgeSubtitle)}>{institution}</p>
            )}
            <p className={css(S.BadgeYear)}>{year}</p>
            {entities.length > 0 && (
                <Flex column hAlign={'end'} className={css(S.FilterLinks)}>
                    {entities.map((entity, i) => (
                        <Link
                            to={entity.link}
                            className={css(S.FilterLink)}
                            key={i}
                            onClick={() => {
                                tracker.log('AwardClick', {
                                    url: new URL(window.location.href),
                                    targetTracker: 'archiproTracker',
                                    data: {
                                        ExtraData: JSON.stringify({
                                            link: entity.link,
                                        }),
                                    },
                                });
                            }}
                        >
                            {entity.label}
                            <ChevronRight
                                variables={{
                                    '1xSize': pxToRem(10),
                                }}
                            />
                        </Link>
                    ))}
                </Flex>
            )}
        </div>
    );

    return (
        <MessageTooltip
            trigger={
                <div
                    className={css(S.BadgeSpacer)}
                    onMouseEnter={() => setBadgeHovered(true)}
                    onMouseLeave={() =>
                        debouncedSetBadgeHovered.run(infoHovered)
                    }
                >
                    {badge}
                </div>
            }
            open={badgeHovered || infoHovered}
            content={{
                children: info,
                className: css(S.BadgeInfo),
                variables: {
                    maxWidth: pxToRem(400),
                },
                padding: 0,
            }}
            pointing={true}
            position={'below'}
            align={'start'}
        />
    );
};

export default AwardBadge;
