import type { StyleRule } from '@archipro-design/aria';
import {
    boxClassName,
    buttonClassName,
    flexClassName,
    inputClassName,
    pxArrayToRem,
    pxToRem,
    textInputClassName,
    textInputSlotClassNames,
} from '@archipro-design/aria';
import { COMPANY_INFO_HEADER_MARGIN } from '../../constants';

export const SubmitButton: StyleRule = ({ theme }) => {
    return {
        paddingTop: pxToRem(32),
        [`& .${buttonClassName}`]: {
            marginTop: 0,
            borderRadius: pxToRem(4),
        },
        [theme.screen.tablet]: {
            paddingTop: pxToRem(18),
            [`& .${buttonClassName}`]: {
                float: 'right',
                height: pxToRem(62),
                borderRadius: pxToRem(4),
            },
        },
    };
};

export const SuccessCaption: StyleRule = ({ theme }) => ({
    marginTop: pxToRem(12),
    marginBottom: pxToRem(32),
    color: theme.siteVariables.colors.charcoal[100],

    [theme.screen.tablet]: {
        marginTop: pxToRem(9),
    },
});

export const CaptionLink: StyleRule = () => ({
    all: 'unset',
    [`&:hover`]: {
        cursor: 'pointer',
    },
});

export const EnquiryTitle: StyleRule = () => ({
    marginBottom: pxToRem(18),
});

export const EnquiryMessage: StyleRule = () => ({
    marginBottom: pxToRem(18),
    whiteSpace: 'pre-wrap',
});

export const ButtonContainer: StyleRule = () => ({
    width: '100%',
});

export const EnquiryModalOverrides: StyleRule = ({ theme, flow }) => ({
    [theme.screen.tablet]: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: flow !== 'sign-up' ? pxToRem(COMPANY_INFO_HEADER_MARGIN) : 0,
        [`& .${textInputSlotClassNames.primaryContent}`]: {
            minHeight: pxToRem(66),
        },

        [`&.${textInputClassName}:first-child`]: {
            minHeight: pxToRem(140),
        },
        [`& #enquiryForm`]: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            [`& .ui-box:has(textarea)`]: {
                height: pxToRem(1),
            },
            [`& .${textInputClassName}:first-child`]: {
                height: '100%',
                [`& textarea`]: {
                    height: '100% !important',
                    margin: 0,
                    padding: pxArrayToRem([24, 20]),
                },
                [`& .${boxClassName} .${boxClassName}`]: {
                    height: '100%',
                },
                [`& .${flexClassName}:nth-child(2)`]: {
                    display: 'none',
                    height: 0,
                },
            },
        },

        [`&.${inputClassName} > .${boxClassName}`]: {
            minHeight: pxToRem(30),
        },
    },
});
