import {
    Divider,
    Flex,
    Caption,
    useStyles,
    useTheme,
    pxToRem,
    useAppDisplayModeContext,
} from '@archipro-design/aria';
import EnquiryButton from '../enquiry-button/EnquiryButton';
import { useCallback, useEffect, useState } from 'react';
import * as S from './EnquiryLoginSignup.style';
import { usePublicUser } from '~/modules/user/hook/use-public-user';
import InlineSocialLogin from '~/modules/user/component/social-login/inline-social-login/InlineSocialLogin';
import InlineLoginSignUpForm from '~/modules/user/component/login-component/inline-login-signup-form/InlineLoginSignUpForm';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import type { LoginFlow } from '~/modules/user/hook/use-login-flow';
import { useControllableValue } from 'ahooks';
import { useUser } from '~/modules/user/hook/use-user';
import RecognisedUserWelcome from './RecognisedUserWelcome';

interface EnquiryLoginSignupProps {
    enquiryVariantControl?: string;
    isEnquiryValid: boolean;
    defaultPublicUser?: PublicUser;
    defaultFlow?: LoginFlow;
    flowProps?: ReturnType<typeof useControllableValue<LoginFlow>>;
}

const EnquiryLoginSignup = ({
    enquiryVariantControl,
    isEnquiryValid,
    defaultPublicUser,
    defaultFlow,
    flowProps,
}: EnquiryLoginSignupProps) => {
    const { css } = useStyles();
    const theme = useTheme();
    const { mobile } = useAppDisplayModeContext();
    const { publicUser, handleUserExists, publicUserLoginFlow } = usePublicUser(
        'enquiry',
        defaultPublicUser
    );
    const user = useUser();

    const [loginExposed, setLoginExposed] = useState(
        enquiryVariantControl === 'exposed'
    );

    const defaultFlowObject = useControllableValue({
        defaultValue: defaultFlow,
        defaultValuePropName: 'defaultFlow',
    });
    const [flow, setFlow] =
        flowProps !== undefined ? flowProps : defaultFlowObject;

    const socialUserCheck = (email: string) => {
        handleUserExists(email);
    };

    const controlledEmailBlur = (email: string) => {
        //stopping the form automatically moving through if the flow is undefined however still allowing the form to swap between login and signup if the flow is defined
        if (flow) {
            handleUserExists(email);
        }
    };

    const [uniqueSocialSignin, setUniqueSocialSignin] =
        useState<boolean>(false);
    const [useEmail, setUseEmail] = useState<boolean>(false);
    const welcomeHeader = `Welcome back, ${publicUser?.firstName}`;

    const [welcomeMessage, setWelcomeMessage] = useState<string>('');

    const existingUser = publicUser?.type === 'existing';

    const updateLoginSignupMessaging = useCallback(() => {
        if (existingUser && uniqueSocialSignin && !useEmail) {
            setWelcomeMessage(
                `Looks like you created your account using ${publicUser.socialLoginOptions[0]}. We’ll use this to log you in.`
            );
        } else if (existingUser) {
            setWelcomeMessage('Log in to send enquiry');
        }
    }, [existingUser, publicUser, uniqueSocialSignin, useEmail]);

    useEffect(() => {
        publicUserLoginFlow && setFlow(publicUserLoginFlow);
        updateLoginSignupMessaging();
    }, [publicUserLoginFlow, setFlow, updateLoginSignupMessaging]);

    useEffect(() => {
        if (publicUser?.socialLoginOptions?.length === 1) {
            setUniqueSocialSignin(true);
        }
        if (publicUser?.socialLoginOptions?.length === 0) {
            setUseEmail(true);
        }
    }, [
        publicUser?.socialLoginOptions?.length,
        publicUserLoginFlow,
        setFlow,
        user.__typename,
    ]);

    if (!loginExposed) {
        return (
            <Flex hAlign="end" className={css(S.ButtonContainer)}>
                <EnquiryButton
                    onClick={() => {
                        setLoginExposed(true);
                    }}
                    buttonText="Continue"
                    disabled={!isEnquiryValid}
                />
            </Flex>
        );
    }

    return (
        <div className={css(S.LoginSignupContainer)}>
            {existingUser ? (
                <>
                    <RecognisedUserWelcome
                        welcomeHeader={welcomeHeader}
                        welcomeMessage={welcomeMessage}
                    />
                    {useEmail ? (
                        <>
                            <InlineLoginSignUpForm
                                flow={flow}
                                onSubmitButtonClick={socialUserCheck}
                                defaultPublicUser={publicUser}
                                isEnquiryValid={isEnquiryValid}
                                onEmailBlur={controlledEmailBlur}
                                authSource="enquiry"
                            />
                        </>
                    ) : (
                        <>
                            <InlineSocialLogin
                                disableSignin={!isEnquiryValid}
                                publicUser={publicUser}
                                authSource="enquiry"
                                skipPostSignUp={true}
                            />
                            <Caption
                                variant="04"
                                variables={{
                                    color: theme.siteVariables.colors
                                        .charcoal[100],
                                }}
                                design={{ marginTop: pxToRem(20) }}
                                className={css(S.UseEmailText)}
                            >
                                Not you? &nbsp;
                                <span
                                    onClick={() => {
                                        setUniqueSocialSignin(false);
                                        setUseEmail(true);
                                    }}
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Use another account
                                </span>
                            </Caption>
                        </>
                    )}
                </>
            ) : (
                <>
                    <Caption
                        variant={mobile ? '02' : '01'}
                        design={{ marginBottom: pxToRem(mobile ? 12 : 16) }}
                    >
                        {flow === 'sign-up'
                            ? 'Sign up to send enquiry'
                            : 'Log in or sign up to send enquiry'}
                    </Caption>
                    {flow !== 'sign-up' && (
                        <>
                            <InlineSocialLogin
                                disableSignin={!isEnquiryValid}
                                publicUser={publicUser}
                                authSource="enquiry"
                                skipPostSignUp={true}
                            />
                            <Divider
                                color="grey"
                                content={'or'}
                                size={1}
                                design={{
                                    width: '100%',
                                    marginTop: pxToRem(8),
                                    marginBottom: pxToRem(8),
                                }}
                                variables={{
                                    dividerPrimaryColor:
                                        theme.siteVariables.colors
                                            .charcoal[100],
                                    dividerPadding: pxToRem(24),
                                    textFontSize: pxToRem(12),
                                }}
                            />
                        </>
                    )}
                    <InlineLoginSignUpForm
                        flow={flow}
                        onSubmitButtonClick={socialUserCheck}
                        defaultPublicUser={publicUser}
                        isEnquiryValid={isEnquiryValid}
                        onEmailBlur={controlledEmailBlur}
                        authSource="enquiry"
                    />
                </>
            )}
            {useEmail && flow === 'login' && (
                <Caption
                    variant="04"
                    design={{ marginTop: pxToRem(15) }}
                    className={css(S.ForgotPasswordButton)}
                    onClick={() => {
                        window.location.href = '/forgot-password';
                    }}
                >
                    Forgot password?
                </Caption>
            )}
        </div>
    );
};

export default EnquiryLoginSignup;
