import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const EnquiryTitle: StyleRule = () => ({
    textTransform: 'uppercase',
    marginBottom: pxToRem(15),
});

export const CustomEnquiryMessage: StyleRule = () => ({
    whiteSpace: 'pre-wrap',
    marginBottom: pxToRem(15),
});
