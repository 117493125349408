import type { BasicPlace, DetailedPlace } from '~/modules/location/type';
import distance from '@turf/distance';

export const getClosestPlace = (
    reference: DetailedPlace,
    places: DetailedPlace[]
): DetailedPlace | undefined => {
    const distances = [...places].sort((A, B) => {
        const distanceToA = distance(
            [A.latitude, A.longitude],
            [reference.latitude, reference.longitude]
        );
        const distanceToB = distance(
            [B.latitude, B.longitude!],
            [reference.latitude, reference.longitude]
        );

        return distanceToA - distanceToB;
    });

    return distances[0];
};

// use getClosestPlace if possible
export const getClosestBasicPlace = (
    reference: BasicPlace,
    places: BasicPlace[]
): BasicPlace | undefined => {
    const LONG_DISTANCE = 9999999;
    const distances = [...places].sort((A, B) => {
        if (
            !A.latitude ||
            !A.longitude ||
            !B.latitude ||
            !B.longitude ||
            !reference.latitude ||
            !reference.longitude
        ) {
            return LONG_DISTANCE;
        }
        const distanceToA = distance(
            [A.latitude, A.longitude],
            [reference.latitude, reference.longitude]
        );
        const distanceToB = distance(
            [B.latitude, B.longitude],
            [reference.latitude, reference.longitude]
        );

        return distanceToA - distanceToB;
    });

    return distances[0];
};
