import { useStyles } from '@archipro-design/aria';
import React from 'react';
import { Flex, Image } from '@archipro-design/aria';
import * as S from './BannerAwards.style';
import type { AwardBadgeProps } from '@modules/page-builder/component/detail/banner-awards/AwardBadge';
import { PLACEHOLDER_AWARD_BADGE } from '@modules/page-builder/component/detail/banner-awards/AwardBadge';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import type { AwardFilterLink } from '@modules/page-builder/util/map-award-entity-links';
import { filterAwardEntities } from '@modules/page-builder/util/map-award-entity-links';
import { ChevronRight } from '@archipro-design/icons';
import { pxToRem } from '@fluentui/react-northstar';
import { useTracker } from '../../../../../../../tracker/src/react-bindings';
import { Link } from '@remix-run/react';

const AwardDrawerItem = ({
    title,
    institution,
    year,
    badgeThumbUrl,
    awardedEntities,
    professionalUrl,
    toggleDrawer,
}: AwardBadgeProps) => {
    const { css } = useStyles();
    const config = useConfigContext();

    const tracker = useTracker();

    const badgeThumb = badgeThumbUrl
        ? badgeThumbUrl
        : `${config.assetsBaseUrl}${PLACEHOLDER_AWARD_BADGE}`;

    const entities: AwardFilterLink[] =
        professionalUrl && awardedEntities
            ? filterAwardEntities(
                  awardedEntities,
                  professionalUrl,
                  title,
                  institution,
                  year
              )
            : [];

    return (
        <Flex>
            <div className={css(S.Badge)}>
                <Image
                    src={badgeThumb}
                    alt={title}
                    width={'100%'}
                    height={'100%'}
                    layout={'fill'}
                    objectFit={'cover'}
                />
            </div>
            <Flex column className={css(S.AwardDrawerInfo)}>
                <p className={css(S.BadgeTitle)}>{title}</p>
                {!!institution && (
                    <p className={css(S.BadgeSubtitle)}>{institution}</p>
                )}
                <p className={css(S.BadgeSubtitle)}>{year}</p>
                {entities.length > 0 && (
                    <Flex space={'between'} className={css(S.FilterLinks)}>
                        {entities.map((entity, i) => (
                            <Link
                                to={entity.link}
                                className={css(S.FilterLink)}
                                key={i}
                                onClick={() => {
                                    tracker.log('AwardClick', {
                                        url: new URL(window.location.href),
                                        targetTracker: 'archiproTracker',
                                        data: {
                                            ExtraData: JSON.stringify({
                                                link: entity.link,
                                            }),
                                        },
                                    });
                                    toggleDrawer?.();
                                }}
                            >
                                {entity.label}
                                <ChevronRight
                                    variables={{
                                        '1xSize': pxToRem(10),
                                    }}
                                />
                            </Link>
                        ))}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default AwardDrawerItem;
